<template>
  <div>
    <table class="table table-condensed table-bordered">
      <thead>
        <tr>
          <th>Prix</th>
          <th>Date</th>
          <th>Actuel</th>
          <th>Principal</th>
          <th>Promotion</th>
          <th>Created By</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <price-tr
          v-for="(p, index) in prices"
          :key="index"
          :price="p"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
    import PriceTr from './priceTr.vue'
    export default {
        props: {
            prices: { type: Array, required: true }
        },
        components: {PriceTr}

    }
</script>

<style lang="scss" scoped>

</style>